html {
  height: -webkit-fill-available;
}
.App{
  /* font-family: 'DM Sans'; */
  // min-height: -webkit-fill-available;
  max-width: 100vh;
  .login {
    margin-top: 100px;
  }
}
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
body{
  overflow: hidden;
}
.mx-3{
  margin-left: 3px;
  margin-right: 3px;
}
.room{
    position: relative;

  .chakra-tabs__tab-panel {
    padding-left: 0;
    padding-right:0;
  }
  .heading{
      // font-family: 'DM Sans';
      color: rgb(70, 70, 70);
      font-size: 2rem;
      font-weight: 500;
      z-index: 1000;
  }
  .messages{
      padding: 0 0.5rem;
      padding-right:0;
      .message{
          line-height: 22px;
          padding-right: 0.5rem;
          word-break: break-word;
          position: relative;
          align-items: center;
          width: 100%;
          font-size: 14px;
          &:hover{
              .reply_icon{
                  cursor: pointer;
                  display: block;
              }
          }
          .msg_reply {
              width: fit-content;
              max-width: 250px;
              cursor: pointer;
          }
          .msg{
              position: relative;
              color: rgba(0,0,0,.7);
              min-width: 70px;
          }
          .reply_icon{
            color: #637381;
            display: none;
            position: absolute;
            right: 10px;
            background: white;
            border-radius: 13px;
            bottom: -10px;
            padding: 3px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          }
      }
      .my-message{
            display: flex;
            align-self: flex-end;
            flex-direction: row-reverse;
            .msg{
                background: #B3C7E4;
                min-width: 70px;
            }
      }

  }

  .messages > div{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: auto !important;
      overflow: auto;
      // height: 400px;
      height: calc(100vh - 110px); /* fallback for unsupported browsers */
      // height: calc(-webkit-fill-available - 110px);
      padding: 0.5rem;
  }
  .messages.rep_active > div{
      height: calc(100vh - 160px); /* fallback for unsupported browsers */
      // height: calc(-webkit-fill-available - 160px);
  }
  .notifications > div{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: auto !important;
      overflow:auto;
      padding: 0.5rem;
      max-height: calc(100vh - 60px);
  }
  .notifications {
      position: relative;
      padding: 0 0.5rem;
      padding-right:0;
      .notification{
        padding-right: 0.5rem;
        word-break: break-word;
        .msg{
            color: rgba(0,0,0,.7);
            min-width: 70px;
        }
      }
      
  }
  .show_modal_add_noti {
        position: absolute;
        top: 8px;
        right: 10px;
        z-index: 100;
    }
  .form{
      position: relative;
      z-index: 1000;
      border-top:1px solid #919eab47;
      padding-right: 16px;
      padding-bottom: 13px;
      padding-left:16px;
      font-size: 14px;
      
      .input_message{
          width: calc(100% - 60px);
          
          input{
            padding-top:14px;
            width: 100%;
            margin-top: 2px;
          }
      }
      .reply{
        margin-bottom: 8px;
        position: relative;
        &_label{
            display: flex;
            top: -22px;
            gap: 5px;
        }
        &_content{
          color: #919EAB;
          max-width: 90%;
        }
        &_close{
          position: absolute;
          right: 0px;
          top: 0px;
          color: #637381;
          border-radius: 10px;
          font-size: 18px;        
        }
      }
      
      
  }
  .noti_content, .message_content {
      position: relative;
      width: fit-content;
  }
  .menu_room {
      max-height: 150px;
      overflow: auto;
  }
  .list_room{
      div{
          min-width: auto !important;
      }
  }
  .max_string {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 200px;
      &_user {
          max-width: 250px;
      }
  }
  ::placeholder {
      opacity: .5; 
  }
  .w-100 {
      width: 100%;
  }
  .button_go_bottom{
      right: 46% !important;
  }
  h2{
      font-weight: bold !important;
  }
  .button_send_message{
    cursor: pointer;
    width: 55px;
    padding-top:16px;
    .text-input{
        font-weight: bold;
        color: #919eabd6;
        padding-left:0.3rem;
      }
  }
  .button_send_message.active_send {
    .text-input{
        color: #0043A5;
    }
    img {
        filter: brightness(0) saturate(100%) invert(15%) sepia(47%) saturate(5388%) hue-rotate(210deg) brightness(98%) contrast(101%);
    }
  }
  .chakra-modal__close-btn::focus{
    box-shadow: none !important;
  }
  .tab-content{
    font-size : 14px;
    color: #637381
  }
  .tab-logout{
    width: 40px;
    padding-top: 10px;
    padding-left: 15px;
  }
  .chakra-menu__menu-list{
    max-height: 400px;
    overflow: auto;
  }
}
.white-space {
    white-space: pre-wrap;
}
.count_msg, .count_noti{
  margin-left: 6px;
  background-color: red;
  border-radius: 6px;
  padding: 0px 5px;
  color: white;
  
}
.w-50 {
  width: 50%;
}
@media(max-width : 30em){
  .room{
      height: unset;
      .messages{
          flex: 1;
      }
      .chakra-toast__inner{
          min-width: 150px;
      }
      
  }

}
.error_connect {
  font-weight: 600;
  background: #c8d5e5f0;
  position: absolute;
  color: red;
  display: flex;
  align-items: center;
  top: 50%;
  left: 35%;
  padding: 2px 7px;
  border-radius: 4px;
  line-height: 25px;

  span{
      font-size: 0.8rem;
      margin-left:5px;
  }
}


.App .mobile_app{
  .messages > div{
    height: calc(100vh - 200px);
  }
  .messages.rep_active > div{
    height: calc(100vh - 250px);
  }
}
@media only screen and (max-width: 370px) {
  .room{
      .messages{
        .message{
          font-size: 13px;
          .msg_reply {
            max-width: 170px;
          }
        }
      }
      .tab-content{
        font-size : 13px;
      }
      .max_string{
        max-width: 140px;
        &_user {
          max-width: 220px;
        }
      }
  }
}
@media only screen and (max-width: 300px) {
  .room{
      .messages{
        .message{
          font-size: 13px;
          .msg_reply {
            max-width: 150px;
          }
        }
      }
      .tab-content{
        font-size : 13px;
      }
      .max_string{
        max-width: 100px;
        &_user {
          max-width: 200px;
        }
      }
  }
}
@media only screen and (max-width: 600px) {
  .room{
      .chakra-toast__inner{
          min-width: 150px !important;
      }
      
  }
}